$pr: Outfit-Regular;
$pm: Outfit-Medium;
$psb: Outfit-SemiBold;
$pb: Outfit-Bold;

@font-face {
  font-family: Outfit-Regular;
  src: url('../assets/fonts/Outfit-Regular.ttf');
}
@font-face {
  font-family: Outfit-Medium;
  src: url('../assets/fonts/Outfit-Medium.ttf');
}
@font-face {
  font-family: Outfit-SemiBold;
  src: url('../assets/fonts/Outfit-SemiBold.ttf');
}
@font-face {
  font-family: Outfit-Bold;
  src: url('../assets/fonts/Outfit-Bold.ttf');
}