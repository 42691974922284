@mixin animation1 {
  transition: all 1s linear;
}
@mixin animation2 {
  transition: background-color 1s linear;
}
@mixin btn-animation {
  transition: 0.5s all;
}
@mixin padding-tb {
  padding: 80px 0px;
  @media (max-width: 1024px) {
    padding: 50px 0px;
  }
}
@mixin tab-padding-tb {
  @media (max-width: 1024px) {
    padding: 20px 0px;
  }
}
@mixin flexcenter {
  display: flex;
  align-items: center;
}
@mixin jflexcenter {
  display: flex;
  justify-content: center;
}
@mixin allflexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin heading($fsize, $ffamily, $color, $text-transform) {
  font-size: $fsize;
  font-family: $ffamily;
  color: $color;
  text-transform: $text-transform;
}
@mixin paragraph($fsize, $ffamily, $color) {
  font-size: $fsize;
  font-family: $ffamily;
  color: $color;
}
// animation: shake 0.5s;
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
@keyframes bt-bottomTop-40 {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 20px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
