@import './variables';
@import './typography';
@import './mixins';

header {
  margin-bottom: -80px;
  padding-top: 5px;
  .cheader {
    @include flexcenter;
    justify-content: space-between;
    .logo {
      @media (max-width: 1536px) {
        width: 280px;
      }
      @media (max-width: 1280px) {
        width: 200px;
      }
      @media (max-width: 576px) {
        width: 170px;
      }
    }
    .navigation {
      margin-top: 15px;
      @media (max-width: 1024px) {
        margin-top: 0px;
        width: 100%;
        position: fixed;
        z-index: 99;
        top: 0;
        left: -200%;
        bottom: 0;
        right: 0;
        padding-top: 100px;
        background-color: rgba($color: $black, $alpha: 0.9);
      }
      @media (max-width: 1024px) {
        width: 100%;
      }
      > ul {
        margin: 0;
        padding: 0;
        @include flexcenter;
        @media (max-width: 1024px) {
          flex-direction: column;
          align-items: flex-start;
          padding: 0px 30px;
        }
        > li {
          list-style: none;
          margin: 0px 30px;
          @media (max-width: 1536px) {
            margin: 0px 20px;
          }
          @media (max-width: 1280px) {
            margin: 0px 10px;
          }
          @media (max-width: 1024px) {
            margin: 10px 0px;
          }
          position: relative;
          
          &:last-child {
            font-family: $pb;
            color: $primary;
            text-transform: uppercase;
            padding: 7px 30px;
            border-radius: 5px;
            border: 2px solid $secondary;
            background-color: $secondary;
            @include btn-animation;
            a {
              color: $black;
            }
            &:hover {
              background-color: transparent;
              border: 2px solid $secondary;
              a {
                color: $secondary;
                border: none;
              }
            }
            @media (max-width: 1280px) {
              padding: 7px 25px;
            }
            @media (max-width: 576px) {
              font-size: 15px;
              padding: 9px 25px;
            }
          }
          > a {
            text-decoration: none;
            @include heading(
              20px,
              $pr,
              rgba($color: $white, $alpha: 1),
              uppercase
            );
            @include animation2;
            padding-bottom: 10px;
            &:hover {
              border-bottom: 3px solid rgba($color: $secondary, $alpha: 1);
            }
            @media (max-width: 1024px) {
              font-size: 18px;
              color: $white;
              border-bottom: 2px solid transparent;
              &:hover {
                border-bottom: 2px solid transparent;
                color: $primary;
              }
            }
          }

          /* Submenu styling */
          .submenu {
            list-style-type: none;
            position: absolute;
            top: 100%;
            left: 0;
            display: block;
            visibility: hidden;
            background-color: rgba(0, 0, 0, 0.8);
            transform-origin: top center;
            transform: translateY(10px);
            transition: all 0.5s ease-in-out;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
					  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
					  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
            opacity: 0;
            z-index: 1000;

            @media (max-width: 1024px) {
              visibility: visible;
              opacity: 1;
              position: relative;
              padding-left: 20px;
            }

            > li {
              display: block;
              min-width: 340px;
              padding: 12px 20px;
              border-bottom: 1px solid #333;
              transition: all 0.3s ease-in-out;

              @media (max-width: 1024px) {
                min-width: initial;
                padding: 6px 12px;
              }

              &:last-child {
                border: none;
              }

              > a {
                position: relative;
                z-index: 11;
                font-size: 18px;
                color: rgba($color: $white, $alpha: 1);
                width: 100%;

                @media (max-width: 1024px) {
                  font-size: 16px;
                }
              }
              &:hover {
                background: rgba($color: $secondary, $alpha: 1);
                > a {color: rgba($color: $black, $alpha: 0.9)}
              }
            }
          }

          &:hover{
            > .submenu {
              visibility: visible;
              opacity: 1;
              -webkit-transform: translateY(1);
              -moz-transform: translateY(1);
              -ms-transform: translateY(1);
              -o-transform: translateY(1);
              transform: translateY(1);
            }
          }
        }
      }
    }
    .active {
      left: 0%;
    }
    .hamburger {
      display: none;
      position: absolute;
      top: 25px;
      right: 0;
      width: 60px;
      height: 60px;
      transition-duration: 0.5s;
      @media (max-width: 1024px) {
        position: relative;
        z-index: 999 !important;
        display: block;
        margin-top: -50px;
      }
      @media (max-width: 576px) {
        width: 10px;
        transform: scale(0.7);
      }
      .icon {
        transition-duration: 0.5s;
        position: absolute;
        height: 4px;
        width: 30px;
        top: 30px;
        background-color: $secondary;

        &:before {
          transition-duration: 0.5s;
          position: absolute;
          width: 30px;
          height: 4px;
          background-color: $secondary;
          content: '';
          top: -10px;
        }

        &:after {
          transition-duration: 0.5s;
          position: absolute;
          width: 30px;
          height: 4px;
          background-color: $secondary;
          content: '';
          top: 10px;
        }
      }

      &.open {
        .icon {
          transition-duration: 0.5s;
          background: transparent;

          &:before {
            background-color: $white !important;
            transform: rotateZ(45deg) scaleX(1.25) translate(6.5px, 6.5px);
          }

          &:after {
            background-color: $white !important;
            transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -6px);
          }
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.active {
  left: 0% !important;
}
